import { createCss } from '@stitches/react'
import {
  gray,
  blue,
  red,
  green,
  tomato,
  brown,
  slate,
  slateDark,
  brownDark,
  grayDark,
  tomatoDark,
  blueDark,
  redDark,
  greenDark,
} from '@radix-ui/colors'

export const { styled, css, getCssString, theme, keyframes } = createCss({
  theme: {
    colors: {
      ...gray,
      ...blue,
      ...red,
      ...green,
      ...tomato,
      ...brown,
      ...slate,
      primary: '$brown3',
    },
    sizes: {
      sidebarWidth: '260px',
    },
    space: {
      sidebarWidth: '260px',
    },
  },
})

export const darkTheme = theme({
  colors: {
    ...grayDark,
    ...blueDark,
    ...redDark,
    ...greenDark,
    ...tomatoDark,
    ...brownDark,
    ...slateDark,
  },
})
