import { useState } from 'react'
import { Link, routes, useMatch } from '@redwoodjs/router'
import {
  Nav,
  MenuItemStyled,
  MenuItemTextStyled,
  Account,
  DropdownMenuContent,
  AccountMenuItemStyled,
  AccountPath,
  ListStore,
  StoreSelected,
  AccountListMenu,
} from './styled'
import {
  ViewGridAddIcon,
  TemplateIcon,
  SwitchVerticalIcon,
  CurrencyDollarIcon,
  ColorSwatchIcon,
  TicketIcon,
  DotsVerticalIcon,
  CogIcon,
  CashIcon,
  PlusSmIcon,
  EyeIcon,
} from '@heroicons/react/outline'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { LogoAdmin } from '../Logo'

const Sidebar = () => {
  const menus = [
    {
      name: 'Dashboard',
      path: routes.home,
      icon: <TemplateIcon width={22} />,
    },
    {
      name: 'Products',
      path: routes.product,
      icon: <ViewGridAddIcon width={22} />,
    },
    {
      name: 'Orders',
      path: routes.order,
      icon: <SwitchVerticalIcon width={22} />,
    },
    {
      name: 'Subscription',
      path: routes.subscription,
      icon: <CurrencyDollarIcon width={22} />,
    },
    {
      name: 'Discount',
      path: routes.discount,
      icon: <TicketIcon width={22} />,
    },
    {
      name: 'License',
      path: routes.license,
      icon: <ColorSwatchIcon width={22} />,
    },
  ]

  const accountMenus = [
    {
      name: 'Setting',
      path: routes.product,
      icon: <CogIcon width={22} />,
    },
    {
      name: 'Payouts',
      path: routes.product,
      icon: <CashIcon width={22} />,
    },
    {
      name: 'View Store',
      path: routes.product,
      icon: <EyeIcon width={22} />,
    },
  ]
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (v) => {
    setIsOpen(v)
  }

  return (
    <Nav className="flex justify-between flex-col p-4 fixed">
      <div>
        <LogoAdmin />
        <div>
          <ul>
            {menus.map((menu, key) => (
              <li key={key}>
                <MenuItem menu={menu} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <DropdownMenu.Root onOpenChange={handleOpen}>
        <DropdownMenu.Trigger className="outline-none">
          <Account
            css={{
              background: isOpen ? 'white' : 'transparent',
            }}
            className={`p-3 rounded-lg cursor-pointer transition-all ${
              isOpen ? 'shadow-menu' : null
            }`}
          >
            <div className="grid gap-2 items-center">
              <div className="w-10">
                <img
                  className="w-10 h-10 rounded-md"
                  src="https://images.unsplash.com/photo-1510172876698-9833bc76c3d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                  alt=""
                />
              </div>
              <div className="w-full text-left">
                <span className="font-semibold line-clamp-1">Qamasy Store</span>
                <AccountPath className="line-clamp-1 text-sm">
                  qamasy
                </AccountPath>
              </div>
              <div>
                <DotsVerticalIcon width={20} />
              </div>
            </div>
          </Account>
        </DropdownMenu.Trigger>
        <DropdownMenuContent>
          <div className="bg-white rounded-lg shadow-menu">
            <ListStore className="py-2">
              <li>
                <div className="py-1 px-3">
                  <StoreSelected>Qamasy</StoreSelected>
                </div>
              </li>
              <li>
                <AccountMenuItem
                  menu={{
                    name: 'New Store',
                    path: '/product',
                    icon: <PlusSmIcon width={22} />,
                  }}
                />
              </li>
            </ListStore>
            <AccountListMenu className="py-2">
              {accountMenus.map((menu, key) => (
                <li key={key}>
                  <AccountMenuItem menu={menu} />
                </li>
              ))}
            </AccountListMenu>
          </div>
        </DropdownMenuContent>
      </DropdownMenu.Root>
    </Nav>
  )
}

export default Sidebar

const MenuItem = ({ menu }) => {
  const matchInfo = useMatch(menu.path())
  return (
    <MenuItemStyled
      as={Link}
      to={menu.path()}
      className={`flex items-center py-2 px-3 rounded-md transition-all ${
        matchInfo.match ? 'activeLink' : null
      }`}
    >
      {menu.icon}
      <div className="ml-3 font-medium">
        <MenuItemTextStyled>{menu.name}</MenuItemTextStyled>
      </div>
    </MenuItemStyled>
  )
}

const AccountMenuItem = ({ menu }) => {
  return (
    <AccountMenuItemStyled className="flex items-center py-1 px-3 transition-all">
      {menu.icon}
      <div className="ml-3 font-medium">
        <MenuItemTextStyled>{menu.name}</MenuItemTextStyled>
      </div>
    </AccountMenuItemStyled>
  )
}
