import Seo from '@/components/Seo'
import Sidebar from '@/components/Sidebar'
import DashboardHeader from '@/components/Dashboard/Header'
import { styled } from '@/styled'

type BaseLayoutProps = {
  children?: React.ReactNode
}

const BaseLayout = ({ children }: BaseLayoutProps) => {
  return (
    <div>
      <Seo />
      <div className="flex">
        <Sidebar />
        <Content className="absolute right-0 top-0">
          <DashboardHeader />
          <div className="max-w-screen-lg mx-auto px-10">
            <div className="mt-8 pb-44">{children}</div>
          </div>
        </Content>
      </div>
    </div>
  )
}

export default BaseLayout

const Content = styled('div', {
  left: '$sidebarWidth',
})
