import { MenuAlt1Icon, PlusIcon } from '@heroicons/react/outline'
import { MenuButton, Search, AddButton } from './styled'

const DashboardHeader = () => {
  return (
    <header>
      <div className="flex justify-between h-24 items-center px-10 relative">
        <div>
          <div className="flex -mx-2 items-center">
            <div className="px-2">
              <MenuButton className="p-2 rounded-md h-9 w-9">
                <MenuAlt1Icon width={20} />
              </MenuButton>
            </div>
            <div className="px-2">
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Search
                  type="text"
                  placeholder="Search"
                  className="h-9 p-2 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex -mx-2 items-center">
            <div className="px-2">
              <img
                className="h-8 w-8 object-cover rounded-full"
                src="https://images.unsplash.com/photo-1628973080310-9fedbef71a49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80"
                alt=""
              />
            </div>
            <div className="px-2">
              <AddButton className="flex items-center justify-center h-9 w-9 rounded-full">
                <PlusIcon width={24} />
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default DashboardHeader
