import { styled } from '@/styled'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export const Logo = styled('div', {
  height: '4rem',
})

export const Nav = styled('nav', {
  background: '$primary',
  width: '$sidebarWidth',
  minHeight: '100vh',
  flex: 'none',
})

export const MenuItemStyled = styled('div', {
  color: '$brown10',
  '&:hover': {
    background: '$brown4',
  },
  '&.activeLink': {
    background: '$brown5',
  },
})

export const MenuItemTextStyled = styled('span', {
  color: '$gray11',
})

export const Account = styled('div', {
  '& > div': {
    gridTemplateColumns: '2.5rem auto 16px',
  },
  '&:hover': {
    background: '$brown5',
  },
})

export const AccountPath = styled('span', {
  color: '$brown9',
})

export const DropdownMenuContent = styled(DropdownMenu.Content, {
  width: 228,
  paddingBottom: 12,
})

export const AccountMenuItemStyled = styled('div', {
  color: '$brown10',
  '&:hover': {
    background: '$brown3',
  },
})

export const ListStore = styled('ul', {
  borderBottom: '1px solid $gray6',
})

export const AccountListMenu = styled('ul', {
  color: '$gray10',
})

export const StoreSelected = styled('span', {
  color: '$gray12',
  fontWeight: 'bold',
})
