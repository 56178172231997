import { styled } from '@/styled'

export const MenuButton = styled('button', {
  background: '$slate4',
})

export const Search = styled('input', {
  background: '$slate4',
})

export const AddButton = styled('button', {
  background: '$brown9',
  color: 'white',
})
